import { Injectable } from '@angular/core';
import {
  ConfirmLinkToken,
  ILogin,
  IUser,
  OtpLogin,
  OtpRequest,
  Recovery,
  Register,
  ResetData,
  UpdateUser,
} from '@ea/models';
import { ApiService, LocalStorageService } from '@ea/services';
import { Observable } from 'rxjs';
import environment from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = '/Auths/';
  constructor(private api: ApiService, private storage: LocalStorageService) {}

  login(body: ILogin) {
    return this.api.post<IUser & { uuid: string }>(
      `${this.baseUrl}Login?region=${environment.stripe.country}`,
      //  `/Synerise/Customer/Login?region=${environment.stripe.country}`,
      body
    );
  }

  register(body: Register) {
    return this.api.post<{ response: boolean; uuid: string }>(
      `${this.baseUrl}Register?region=${environment.stripe.country}`,
      //   `/Synerise/Customer/Profile/Create?region=${environment.stripe.country}`,
      body
    );
  }

  registerWithAddress(body: any) {
    return this.api.post<{ response: boolean; uuid: string }>(
      `${this.baseUrl}Create-user-with-address?region=${environment.stripe.country}`,
      //   `/Synerise/Customer/Profile/Create?region=${environment.stripe.country}`,
      body
    );
  }

  getProfile(): Observable<any> {
    return this.api.get<IUser>(
      `${this.baseUrl}Get/Profile?&region=${environment.stripe.country}`
      //`/Synerise/Customer/Profile/Get?token=${token}&region=${environment.stripe.country}`,
    );
  }

  SendEmailChangeRequest(payload: any): Observable<any> {
    return this.api.post<any>(
      `${this.baseUrl}ResetEmail?n&region=${environment.stripe.country}`,
      //`/Synerise/Customer/Profile/ResetEmail?n&region=${environment.stripe.country}`,
      payload
    );
  }

  SendEmailChangeConfirm(payload: any): Observable<any> {
    return this.api.post<any>(
      `${this.baseUrl}ResetEmail/Confirm?n&region=${environment.stripe.country}`,
      //  `/Synerise/Customer/Profile/ResetEmail/Confirm?n&region=${environment.stripe.country}`,
      payload
    );
  }
  recover(body: Recovery) {
    return this.api.post<boolean>(
      `${this.baseUrl}NotAuth/ResetPassword?region=${environment.stripe.country}`,
      //  `/Synerise/Customer/Profile/ResetPassword?region=${environment.stripe.country}`,
      body
    );
  }
  reset(body: ResetData) {
    return this.api.post<boolean>(
      `${this.baseUrl}ResetPassword/Confirm?region=${environment.stripe.country}`,
      body
    );
  }

  updateProfile(body: UpdateUser) {
    return this.api.put<any>(
      `${this.baseUrl}Update/Profile?region=${environment.stripe.country}`,
      body
    );
  }

  chanePasswordAuthUset(cusid: string) {
    return this.api.get<any>(
      `${this.baseUrl}ResetPassword/${cusid}?region=${environment.stripe.country}`
    );
  }

  generateLink(body: Recovery) {
    return this.api.post<boolean>(
      `${this.baseUrl}GenerateLoginLinkEmail?region=${environment.stripe.country}`,
      //`/Synerise/Customer/Profile/GenerateLoginLinkEmail?region=${environment.stripe.country}`,
      body
    );
  }

  confirmMailToken(payload: ConfirmLinkToken): Observable<any> {
    return this.api.post<IUser & { uuid: string }>(
      `${this.baseUrl}EmailLogin/ConfirmMailToken?&region=${environment.stripe.country}`,
      payload
    );
  }
  confirmAccountAndLoginByToken(payload: ConfirmLinkToken): Observable<any> {
    return this.api.post<IUser & { uuid: string }>(
      `${this.baseUrl}EmailLogin/confirmEmail/ConfirmMailToken?&region=${environment.stripe.country}`,
      payload
    );
  }

  generateOtp(body: OtpRequest) {
    return this.api.post<boolean>(
      `${this.baseUrl}GenerateOtpForLogin?region=${environment.stripe.country}`,
      body
    );
  }

  loginByCode(body: OtpLogin) {
    return this.api.post<IUser & { uuid: string }>(
      `${this.baseUrl}LoginWithOtp?region=${environment.stripe.country}`,
      body
    );
  }
}
